/* eslint-disable @typescript-eslint/no-var-requires */
import getAppTenantIdTsg from "./utils/get-app-tenant-id-tsg";
import getAppTenantId from "./utils/get-app-tenant-id";
import getDIAppTenantRegion from "./utils/getAppTenantRegion";
import { HOST_APP_DI, HOST_APP_FAWKES } from "@reporting-n-viz/vnr-entrypoint/dist/host-app";
import { aiopsAuthDataMutation } from "./utils/aiops-authdata-mutation";
import { getState, getAuthState } from "@sparky/framework";
import { getTenantInfo } from "./utils/get-tenant-info";

/* Warning: please do not statically import any dependencies here except for static assets like icons */

const init = async ({ vars, onAuthed, sparkyFramework }: any) => {
  onAuthed(async (params: any) => {
    params.getFeatures();
    params.getLicenseInfo();
    params.getFqdnInfo();

    return await Promise.all([sparkyFramework.loaded, params.getAuthInfo()])
      .then(async () => await import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch"))
      .then(({ prefetch }) => {
        const getCsIdTsg = require("./utils/get-cs-id-tsg").default;
        const getCsId = require("./utils/get-cs-id").default;
        const getLsTenantIdTsg = require("./utils/get-ls-tenant-id-tsg").default;
        const getLsTenantId = require("./utils/get-ls-tenant-id").default;
        const getLsTenantLogData = require("./utils/get-ls-tenant-log-data").default;
        const getInstanceTier = require("./utils/get-instance-tier").default;
        const getSparkyMainState = require("./utils/get-sparky-main-state").default;
        const getManagedAppName = require("./utils/get-managed-app-name").default;
        const getPaParams = require("./utils/get-pa-params").default;
        const getSdwanOnlyFlag = require("./utils/get-sdwan-only-flag").default;
        const getMfeProps = require("./utils/get-mfe-properties").default;
        const hasTsgSupport = sparkyFramework.hasTsgSupport();
        const main = getSparkyMainState(
          hasTsgSupport,
          sparkyFramework.getAuthState,
          sparkyFramework.getMainState,
          sparkyFramework.StartupManager
        );
        const { paiFQDN } = getPaParams(hasTsgSupport, main, sparkyFramework.StartupManager);
        let appTenantRegion = main?.tenant?.info?.region;
        let authData = hasTsgSupport
          ? sparkyFramework.getAuthState()
          : sparkyFramework.getAuthData(main.tenant, main.authInfo, main.loggedInUser);
        const isPAEnabled =
          main?.tenant?.pa_enabled ?? authData?.instances?.isFawkesRunning ?? false;
        const currentInstanceTier = getInstanceTier()?.frameworkInstance;
        const isHybridFramework = getInstanceTier()?.isHybridFramework;
        const hasSCMInstance = getInstanceTier()?.hasSCMInstance;
        const isSDWANOnlyTenant = getSdwanOnlyFlag(authData, currentInstanceTier);
        const hasCDL = authData?.instances?.isRunningStatus("logging_service");
        const paiFeatures = sparkyFramework.getState()?.saseIAMainState?.cosmosFeatures;
        // support new sku/instance
        const isSCMInstanceAvail = authData?.instances?.isRunningStatus("strata_cloud_manager");
        const lsTenantLogData = getLsTenantLogData(authData, isSCMInstanceAvail);
        let managedAppName = getManagedAppName(
          hasTsgSupport,
          sparkyFramework.getAuthState,
          sparkyFramework.StartupManager
        );
        let di;
        let prismaAccess;
        const adem = extractAdemParams(sparkyFramework, authData);
        const appTenantIdPrismaAccess = hasTsgSupport
          ? getAppTenantIdTsg(authData, "prisma_access")
          : undefined;
        if (appTenantIdPrismaAccess && isPAEnabled) {
          prismaAccess = {
            appTenantId: appTenantIdPrismaAccess,
            appTenantRegion,
            appType: managedAppName
          };
        }

        const aiopsAppId = isSCMInstanceAvail ? "strata_cloud_manager" : (currentInstanceTier === "premium" ? "strata_insights" : "strata_insights_free");
        if (
          hasTsgSupport &&
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (authData.instances.some(({ app_id }: any) => app_id === aiopsAppId) || isSDWANOnlyTenant)
        ) {
          managedAppName = isSDWANOnlyTenant ? "cgx" : "strata_insights";
          authData = aiopsAuthDataMutation(authData, currentInstanceTier, isSCMInstanceAvail);
          appTenantRegion = getDIAppTenantRegion(managedAppName, authData);
          di = {
            appTenantId: getAppTenantIdTsg(authData, managedAppName),
            appTenantRegion,
            appType: managedAppName
          };
        }
        const hostApp = [
          "strata_insights_free",
          "strata_insights",
          "strata_insights_ngfw",
          "cgx",
          "strata_cloud_manager"
        ].includes(managedAppName)
          ? HOST_APP_DI
          : HOST_APP_FAWKES;
        const appTenantId = hasTsgSupport
          ? getAppTenantIdTsg(authData, managedAppName)
          : getAppTenantId(main);

        return prefetch(
          vars.api_server_url,
          {
            tokenManager: hasTsgSupport
              ? null
              : sparkyFramework.getTokenManager(main.tenant, main.authInfo, main.loggedInUser),
            csId: hasTsgSupport ? getCsIdTsg(authData, managedAppName) : getCsId(main),
            tsgId: authData.tsg_id,
            appsToTenantMap: new Map([[managedAppName, new Set([appTenantId])]]),
            hasTsgSupport,
            getCurrentTsgToken: sparkyFramework.getCurrentTsgToken,
            hostApp,
            managedAppName,
            appTenantId,
            authData,
            loggedInUser: main?.loggedInUser,
            appTenantRegion: appTenantRegion || "us",
            isEarlyAccessNgfwTenant: main?.tenant?.is_early_access_ngfw_tenant || false,
            isSDWANOnlyTenant,
            lsTenantId: hasTsgSupport ? getLsTenantIdTsg(authData) : getLsTenantId(main),
            currentInstanceTier,
            isHybridFramework,
            hasSCMInstance,
            hasCDL,
            notTelemetryTenant: lsTenantLogData,
            di,
            prismaAccess,
            adem,
            paiFQDN,
            mfeProps: getMfeProps(),
            paiFeatures
          },
          sparkyFramework.actions.setValues
        );
      });
  });

  return await import(/* webpackPreload: true */ "./routes").then((r) => r.default);
};

init.exposes = {
  default: {
    UARComponent: async () => await import("./UAR"),
    Dashboard: async () => await import("./Dashboard"),
    CoPilot: async () => await import("./CoPilot")
  },
  copilot_available_paths: {
    vnr() {
      const authData = getAuthState();
      const tenantInfo = getTenantInfo(authData);
      const globalContext = {
        tenantInfo,
        pdfMode: false,
        licenseInfo: {
          CDL: {
            status: tenantInfo.hasCDL ? "active" : "noLicense"
          }
        }
      };
      const reportsById = getState()?.reporting?.reportsById ?? {};
      const aiAllowed = reportsById?.unified_network_activity_report?.allowed ?? false;
      return [
        {
          path: `/insights/activity_insights/summary`,
          title: "Activity Insights (Overview)",
          isAllowed: () => {
            return (
              aiAllowed &&
              !globalContext.pdfMode &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network."
        },
        {
          path: "/insights/activity_insights/apps",
          title: "Activity Insights (Applications)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant
            );
          },
          description:
            "View your application details, performance analytics, and insights to optimize productivity and security resource allocation."
        },
        {
          path: "/insights/activity_insights/sdwan_apps",
          title: "Activity Insights (SDWAN Applications)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.tenantInfo?.hasSDWAN &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description: "View your SD-WAN Application details, performance analytics and insights"
        },
        {
          path: "/insights/activity_insights/threats",
          title: "Activity Insights (Threats)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Holistic view of the threat environment in your network, with comprehensive analytics to enable you to act and confidently protect yourself."
        },
        {
          path: "/insights/activity_insights/users",
          title: "Activity Insights (Users)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant
            );
          },
          description:
            "Empowering insights into user behavior, application preferences, and security risks for data-driven decision-making and improved user experience."
        },
        {
          path: "/insights/activity_insights/urls",
          title: "Activity Insights (URLs)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Insights into web browsing behavior, URL filtering for security, and data-driven web policies to enhance user web experience and protection."
        },
        {
          path: "/insights/activity_insights/rules",
          title: "Activity Insights (Rules)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Overview of your network rule landscape, automated security responses, and streamlined network policies that allow you to ensure efficient network governance."
        },
        {
          path: "/insights/activity_insights/traffic",
          title: "Activity Insights (Regions)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network."
        }
      ];
    }
  }
};

function extractAdemParams(sparkyFramework: any, authData: any) {
  const apiServerUrl = sparkyFramework?.getMicroAppVars("dem")?.api_server_url;
  const tsgId = authData?.tsg_id;
  if (!apiServerUrl || !tsgId) {
    return undefined;
  }

  return {
    serverURL: apiServerUrl,
    apiMethod: `/api/v3/t/${tsgId}`,
    headers: {
      "prisma-tenant": tsgId
    },
    tsgId
  };
}
export default init;

export { default as extensions } from "./extensions";
